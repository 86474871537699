(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("emotion"), require("empty"), require("html2canvas"), require("md5"));
	else if(typeof define === 'function' && define.amd)
		define(["emotion", "empty", "html2canvas", "md5"], factory);
	else if(typeof exports === 'object')
		exports["client"] = factory(require("emotion"), require("empty"), require("html2canvas"), require("md5"));
	else
		root["client"] = factory(root["emotion"], root["empty"], root["html2canvas"], root["md5"]);
})(globalThis, (__WEBPACK_EXTERNAL_MODULE__45609__, __WEBPACK_EXTERNAL_MODULE__18513__, __WEBPACK_EXTERNAL_MODULE__22701__, __WEBPACK_EXTERNAL_MODULE__31308__) => {
return 